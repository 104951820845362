import './App.css';

function App() {
  return (
    <div className="App">
      <div className='Container'>
        <img className="developmentImage" src={process.env.PUBLIC_URL + "assets/1.jpeg"} alt="img" />
        <h1 className='h1Font'>Page Under Development</h1>

        <p className='bestfont'>We are currently working on this page. Please check back later.</p>
        <p className='bestfont'>Thank you for your patience!</p>
      </div>

    </div>
  );
}

export default App;
